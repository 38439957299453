import { useUserStore } from 'store/user.store';

export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore();
  if ((!userStore.userInfo || !userStore.userInfo?.supplierInfo)
    && process.server
  ) {
    await useSetServerUserInfo();
  }

  if (!SupplierHelper.isQualificationCompleted) {
    return navigateTo('/supplier/qualifications/intro/');
  }
});
